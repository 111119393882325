import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
        <header>
            <h1>Graham Jantz</h1>
            <p>Portfolio: <a href="https://grahamjantz.com" target='_blank' rel="noreferrer">grahamjantz.com</a></p>
        </header>

        <section className="summary">
            <h2>Summary</h2>
            <p>Motivated and enthusiastic Lessons Coordinator with an analytical mindset and keen attention to detail, seeking a dynamic role that fosters career growth and professional development within the IT field. Leveraging 4 years of experience at Long & McQuade Musical Instruments, providing lessons administration and customer service to all clients. Actively pursuing Google IT Support Specialist certification to enhance skills in deskside support, computer skills, troubleshooting, and technical issue resolution.</p>
        </section>

        <section className="skills">
            <h2>Skills</h2>
            <ul>
                <li>Technical Support & Troubleshooting</li>
                <li>Google Workspace</li>
                <li>Customer Service & Communication</li>
                <li>Network Protocols</li>
                <li>Problem Resolution & Analytical Thinking</li>
                <li>Computer Programming</li>
            </ul>
        </section>

        <section className="experience">
            <h2>Experience</h2>

            <div className="experience-item">
                <h3>Google IT Support Specialist Certificate</h3>
                <p>01/2023 - Present</p>
                <p>Comprehensive self-led online study, including 100+ hours of instruction and hands-on assessments covering technical support scenarios, customer service, networking, operating systems, and security.</p>
                <p>Skillset includes: network protocols, cloud computing, Windows & Linux operating systems, systems administration, encryption techniques.</p>
                <p>Completed courses:</p>
                <ul>
                    <li>Technical Support Fundamentals</li>
                    <li>Computer Networking</li>
                    <li>Operating Systems</li>
                </ul>
            </div>

            <div className="experience-item">
                <h3>Long & McQuade</h3>
                <p>05/2022 - Present | Lessons Coordinator</p>
                <p>09/2020 - 05/2022 | Sales & Rental Associate / Lessons Associate</p>
                <p>04/2019 - 09/2020 | Lessons Associate</p>
                <ul>
                    <li>First point of contact for customer inquiries involving registration, scheduling, and day-to-day problem solving requiring exceptional communication and active listening skills</li>
                    <li>Manage student and teacher attendance records, ensuring accuracy</li>
                    <li>Administer monthly teacher commission reports, requiring attention to detail</li>
                    <li>Interview, train, and supervise lesson staff and music instructors, overseeing a team of 1 direct report Lessons Associate and 10 indirect report music instructors</li>
                    <li>Engage and assist customers through the sales, rentals, and special orders of musical instruments and gear.</li>
                </ul>
            </div>
            <div className="experience-item">
                <h3>Web Development Studies</h3>
                <p>05/2022 - Present</p>
                <ul>
                    <li>Self-led online study with a focus on front-end web development skills, earning certificates in JavaScript, React.js, and SQL</li>
                    <li>Designed, developed, deployed and currently maintain various web projects showcased at <a href="https://www.grahamjantz.com">grahamjantz.com</a></li>
                </ul>
            </div>
        </section>

        <section className="education">
            <h2>Education</h2>

            <div className="education-item">
                <h3>University of Lethbridge | B.A. Music</h3>
                <p>Secretary of AES Student Club (2019 - 2020), developed skills in time management, project coordination, and effective teamwork</p>
            </div>

            <div className="education-item">
                <h3>Red Deer Polytechnic | Music Diploma</h3>
                <p>Vice President of Music Society Student Club (2017 - 2018), developed leadership and organizational abilities</p>
            </div>
        </section>

        <footer>
            <p>References available upon request</p>
        </footer>
    </div>
  );
}

export default App;
